import { FinancingModalTriggerMinicart } from "@client/src/Checkout/components/FinancingModalTriggerMinicart";
import classNames from "classnames";
import React, { memo, useEffect, useMemo, useState } from "react";
import { Provider } from "react-redux";
import { BasketLineVariant } from "tsi-common-react/src/apps/checkout/constants";
import { BasketMenuIcon } from "tsi-common-react/src/apps/checkout/containers/BasketMenuIcon";
import { CountdownTimer } from "tsi-common-react/src/apps/countdown-timer/container/CountdownTimer";
import { RichNavigation } from "tsi-common-react/src/apps/rich-navigation/RichNavigation";
import { Image } from "tsi-common-react/src/common/Image";
import Link from "tsi-common-react/src/common/Link";
import { ICountdownTimer } from "tsi-common-react/src/models/countdown-timer.interfaces";
import {
    IWebPageURL,
    isoWebPageURL,
} from "tsi-common-react/src/models/nominals";
import { strToBool } from "tsi-common-react/src/utils/format";
import { useCurrentBreakpoint } from "tsi-common-react/src/utils/hooks";
import {
    getPageSetting,
    isPageInsideIFrame as isPageInsideIFrameCheck,
} from "tsi-common-react/src/utils/settings";
import { useIsSSR } from "tsi-common-react/src/utils/ssr";
import { urls } from "tsi-common-react/src/utils/urls";
import { t } from "ttag";

import { templates } from "@reactivated";

import cocoonLogoWithoutStitch from "../../static/img/global/cocoon-logo-without-stitch.svg";
import cocoonLogo from "../../static/img/global/cocoon-logo.svg";
import hamburgerClose from "../../static/img/global/hamburger-close.svg";
import hamburger from "../../static/img/global/hamburger.svg";
import cocoonLogoStitch from "../../static/img/global/logo-cocoon-stitch.png";
import accountIcon from "../../static/img/icons/account-icon.svg";
import { store } from "../src/store";

interface IBasketMenuIconProps {
    basketURL: IWebPageURL;
    checkoutURL: IWebPageURL;
    isPageInsideIFrame: boolean;
    enableBasketPrequalSDK: boolean;
    isSSR: boolean;
}

interface ISecondaryNavProps extends IBasketMenuIconProps {
    ordersURL: IWebPageURL;
}

const LogoContainer = memo(
    ({ screen, homeURL }: { screen: string; homeURL: IWebPageURL }) => (
        <div className={`logo-container logo-container${screen}`}>
            <Link className="logo" href={homeURL} title="Cocoon Home Page">
                <picture>
                    <img
                        className="header-brand-logo"
                        src={cocoonLogo}
                        alt="Cocoon By Sealy"
                    />
                    <img
                        className="header-brand-logo--mobile"
                        src={cocoonLogoWithoutStitch}
                        alt="Cocoon By Sealy"
                    />
                </picture>
            </Link>
        </div>
    ),
);

const BasketMenuIconContainer = memo(
    ({
        basketURL,
        checkoutURL,
        isPageInsideIFrame,
        enableBasketPrequalSDK,
        isSSR,
    }: IBasketMenuIconProps) => (
        <Provider store={store}>
            <BasketMenuIcon
                basketURL={basketURL}
                checkoutURL={checkoutURL}
                isPageInsideIFrame={isPageInsideIFrame}
                basketLineVariant={BasketLineVariant.MINIMAL_ENHANCED}
                addPopperArrow={true}
                buildPromoComponents={(closeModal) => {
                    if (!enableBasketPrequalSDK) {
                        return null;
                    }
                    return (
                        <Provider store={store}>
                            <FinancingModalTriggerMinicart
                                closeModal={closeModal}
                            />
                        </Provider>
                    );
                }}
                isSSR={isSSR}
            />
        </Provider>
    ),
);

const SecondaryNav = memo(
    ({ ordersURL, ...otherProps }: ISecondaryNavProps) => (
        <div className="secondary-nav">
            <Link href={ordersURL} title="Cocoon by Sealy Orders">
                <img src={accountIcon} alt="Account" />
            </Link>
            <div className="site-nav__main-menu-cart site-nav__secondary-menu__item">
                <BasketMenuIconContainer {...otherProps} />
            </div>
        </div>
    ),
);

export const Template = (props: templates.SiteHeaderTemplate) => {
    const isSSR = useIsSSR();
    const viewport = useCurrentBreakpoint();
    const [mobilePanelVisible, setMobilePanelVisible] = useState(false);
    const [timerReady, setTimerReady] = useState(false);
    const {
        rich_navigation,
        is_enabled,
        timer_queue,
        promo_copy,
        promo_page_url,
        promo_image,
    } = props;

    const supportURL = isSSR
        ? isoWebPageURL.wrap("/")
        : urls.pageURL("support-link");
    const warrantyURL = isSSR
        ? isoWebPageURL.wrap("/")
        : urls.pageURL("warranty-link");
    const homeURL = isSSR ? isoWebPageURL.wrap("/") : urls.pageURL("home");
    const basketURL = isSSR
        ? isoWebPageURL.wrap("/")
        : urls.pageURL("basket-summary");
    const checkoutURL = isSSR
        ? isoWebPageURL.wrap("/")
        : urls.pageURL("checkout-index");
    const offersURL = isSSR
        ? isoWebPageURL.wrap("/")
        : urls.pageURL("offers-link");
    const ordersURL = isoWebPageURL.wrap("/orders/");
    const isPageInsideIFrame = isSSR ? false : isPageInsideIFrameCheck();
    const enableBasketPrequalSDK = isSSR
        ? false
        : strToBool(getPageSetting("show-prequal-cart"));

    const preHeaderClasses = classNames({
        "pre-header": true,
        "pre-header--timer-present": is_enabled,
        "pre-header--timer-ready": is_enabled && timerReady,
    });
    const timerPresentClass = classNames({
        "aux-header--timer-present": is_enabled,
    });

    // As the StremFields converted into strings
    // Rename the fields for aliasing and type compatibility
    const timersConvertedType = useMemo(() => {
        return timer_queue?.timers?.map((time) => {
            const { body_str, mobile_body_str, timer_theme, ...rest } = time;
            return {
                ...rest,
                body: body_str,
                mobile_body: mobile_body_str,
                theme: timer_theme,
            } as ICountdownTimer;
        });
    }, [timer_queue]);

    // Spread props to the child components
    const basketMenuIconProps = {
        basketURL,
        checkoutURL,
        isPageInsideIFrame,
        enableBasketPrequalSDK,
        isSSR,
    };
    const secondaryNavProps = {
        ordersURL,
        ...basketMenuIconProps,
    };

    const toggleMobilePanel = () => {
        if (viewport.belowMobile) {
            setMobilePanelVisible((o) => !o);
        }
    };

    // Mimic legacy mobile-panel code during the ongoing SiteHeaderTemplate work
    useEffect(() => {
        document.body.setAttribute(
            "style",
            `overflow: ${mobilePanelVisible ? "hidden" : "auto"}`,
        );
    }, [mobilePanelVisible]);

    return (
        <>
            <div className={preHeaderClasses}>
                <a
                    className="aux-header__screen-reader-skip"
                    href="#main-content"
                >
                    {t`Skip to main content`}
                </a>
                {is_enabled ? (
                    <div
                        className="aux-header__timer"
                        role="region"
                        aria-label="Sale countdown"
                    >
                        <div className="countdown-timer-container">
                            <CountdownTimer
                                timerData={timersConvertedType}
                                timersExpiredPromo={promo_copy}
                                onTimerReady={() => setTimerReady(true)}
                            />
                        </div>
                    </div>
                ) : promo_copy ? (
                    <div className="aux-header__copy">{promo_copy}</div>
                ) : (
                    <ul className="value-props-list">
                        <li>
                            <Link
                                href={supportURL}
                                title="Learn more about support"
                            >
                                <strong>{t`100% Satisfaction Guarantee`}</strong>
                            </Link>
                        </li>
                        <li>
                            <Link
                                href={supportURL}
                                title="Learn more about support"
                            >
                                <strong>{t`100 Night Trial`}</strong>
                            </Link>
                        </li>
                        <li>
                            <Link
                                href={supportURL}
                                title="Learn more about support"
                            >
                                <strong>{t`Free Shipping`}</strong>
                            </Link>
                        </li>
                        <li>
                            <Link
                                href={warrantyURL}
                                title="Learn more about warranty"
                            >
                                <strong>{t`10 Year Warranty`}</strong>
                            </Link>
                        </li>
                        <li>
                            <Link
                                href={supportURL}
                                title="Learn more about support"
                            >
                                <strong>{t`24/7 Support`}</strong>
                            </Link>
                        </li>
                    </ul>
                )}
                {promo_page_url && !is_enabled && (
                    <div className="shop-container">
                        <a
                            href={promo_page_url}
                            className="button shop-button al-header__shop-button"
                            id="shop-btn-value-props"
                        >
                            {t`Shop`}
                        </a>
                    </div>
                )}
                {!rich_navigation && (
                    <div className="mobile-header-content">
                        <div className="brand brand--mobile">
                            <Link href={homeURL} title="Go to home page">
                                <picture>
                                    <source
                                        media={`(max-width: 768px)`}
                                        srcSet={cocoonLogo}
                                    />
                                    <Image
                                        src={cocoonLogoStitch}
                                        alt={`Cocoon stitch log`}
                                    />
                                </picture>
                            </Link>
                        </div>
                        <div className="site-nav__cart-container  site-nav__cart-container--no-promo">
                            <div className="site-nav__main-menu-cart site-nav__secondary-menu__item">
                                <BasketMenuIconContainer
                                    {...basketMenuIconProps}
                                />
                            </div>
                        </div>
                    </div>
                )}
            </div>
            <header id="aux-header" className={timerPresentClass}>
                <div className="nav-header">
                    {/** if Rich Navigation is configured, render this */}
                    {rich_navigation && (
                        <div className="main-container">
                            <button
                                className="mobile-toggle"
                                aria-controls="mobile-panel"
                                onClick={toggleMobilePanel}
                            >
                                {mobilePanelVisible ? (
                                    <img
                                        src={hamburgerClose}
                                        alt="Close icon"
                                    />
                                ) : (
                                    <img
                                        src={hamburger}
                                        alt="Hamburger navigation icon"
                                    />
                                )}
                            </button>
                            <LogoContainer
                                screen="--desktop"
                                homeURL={homeURL}
                            />
                            {(mobilePanelVisible ||
                                (!mobilePanelVisible &&
                                    !viewport.belowMobile)) && (
                                <div
                                    id="mobile-panel"
                                    className="rich-navigation"
                                    aria-hidden={isSSR}
                                >
                                    <RichNavigation {...rich_navigation} />
                                </div>
                            )}
                            <LogoContainer
                                screen="--mobile"
                                homeURL={homeURL}
                            />
                            {promo_image ? (
                                <>
                                    <Link
                                        href={offersURL}
                                        title="Cocoon by Sealy Offers"
                                    >
                                        <img
                                            src={promo_image}
                                            alt="nav-promo-image"
                                        />
                                    </Link>
                                    <SecondaryNav {...secondaryNavProps} />
                                </>
                            ) : (
                                <SecondaryNav {...secondaryNavProps} />
                            )}
                        </div>
                    )}
                </div>
            </header>
        </>
    );
};
